var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Easily add tooltips to elements or components via the "),_c('code',[_vm._v("<b-tooltip>")]),_vm._v(" component or "),_c('a',{attrs:{"href":"https://bootstrap-vue.org/docs/directives/tooltip","target":"_blank"}},[_vm._v(" v-b-tooltip ")]),_vm._v(" directive (preferred method). ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('positioning')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('triggers')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('title-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('variants')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }